<template>
  <AppLayout>
    <template v-slot:appContent>
      <div>
        <div class="d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h4 class="">Admin Tasks</h4>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-center">
              <div class="col-10 d-flex">
                <div class="col-3">
                  <div class="card ">
                    <div class="card-body">
                      <div>
                        <div class="mb-1">
                          <h5 class="text-bold-400 text-capitalize" style="text-transform: capitalize;">
                            Admin List
                          </h5>
                        </div>
                        <div>
                          <h6 v-for="(admin, index) in admins" :key="index">
                            <span class="text-muted mb-1">{{ admin?.user?.full_name ?? '' }}: </span>
                            <span> {{ countList[admin?.user?.id] ?? 0 }} </span>
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-9">
                  <div class="card ">
                    <div class="card-body">
                      <div>
                        <div class="mb-1">
                          <h5 class="text-bold-400 text-capitalize" style="text-transform: capitalize;">
                            Calendar View
                          </h5>
                        </div>
                        <div>
                          <Qalendar
                              :events="adminJobs"
                              :config="config"
                              @event-was-clicked="eventWasClicked"
                              @event-was-resized="eventWasResized"
                              @event-was-dragged="eventWasDragged"
                              @edit-event="editEvent"
                              @delete-event="deleteEvent"
                              @interval-was-clicked="intervalWasClicked"
                              @day-was-clicked="dayWasClicked"
                              @datetime-was-clicked="datetimeWasClicked"
                              @updated-mode="updatedMode"
                              @updated-period="updatedPeriod"
                          >
                            <template #weekDayEvent="eventProps">
                              <div
                                  :style="{ backgroundColor: 'cornflowerblue', color: '#fff', width: '100%', height: '100%', overflow: 'hidden' }">
                                <span>{{ timeFormattingFunction(eventProps.eventData.time) }}</span>

                                <span>{{ eventProps.eventData.title }}</span>
                              </div>
                            </template>

                            <template #monthEvent="monthEventProps">
                              <div>
                                <span>{{ monthEventProps.eventData.title }}</span>
                              </div>
                            </template>

                            <template #eventDialog="props">
                              <div v-if="props.eventDialogData && props.eventDialogData.title">
                                <div :style="{marginBottom: '8px'}">Edit event</div>

                                <input class="flyout-input" type="text"
                                      :style="{ width: '90%', padding: '8px', marginBottom: '8px' }">

                                <button class="close-flyout" @click="props.closeEventDialog">
                                  Finished!
                                </button>
                              </div>
                            </template>
                          </Qalendar>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </AppLayout>
</template>

<script>
import {Qalendar} from "qalendar";
import AppLayout from "@/layouts/backEnd/AppLayout.vue";
import {mapActions, mapGetters} from "vuex";
import Loader from "@/components/backEnd/mixins/Loader";

export default {
  name: "AdminTask",
  components: {
    AppLayout,
    Qalendar,
  },
  mixins: [Loader],

  data() {
    return {
      config: {
        week: {
          // Takes the value 'sunday' or 'monday'
          // However, if startsOn is set to 'sunday' and nDays to 5, the week displayed will be Monday - Friday
          startsOn: 'monday',
          // Takes the values 5 or 7.
          nDays: 7,
          // Scroll to a certain hour on mounting a week. Takes any value from 0 to 23.
          // This option is not compatible with the 'dayBoundaries'-option, and will simply be ignored if custom day boundaries are set.
          scrollToHour: 5,
        },
        month: {
          // Hide leading and trailing dates in the month view (defaults to true when not set)
          showTrailingAndLeadingDates: false,
        },
        // Takes any valid locale that the browser understands. However, not all locales have been thoroughly tested in Qalendar
        // If no locale is set, the preferred browser locale will be used
        locale: 'en-US',
        style: {
          
        },
        // if not set, the mode defaults to 'week'. The three available options are 'month', 'week' and 'day'
        // Please note, that only day and month modes are available for the calendar in mobile-sized wrappers (~700px wide or less, depending on your root font-size)
        defaultMode: 'month',
        // The silent flag can be added, to disable the development warnings. This will also bring a slight performance boost
        isSilent: true,
        showCurrentTime: true, // Display a line indicating the current time,
        eventDialog: {
          isCustom: false,
          isDisabled: false,
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      admins: 'appAdmins/admins',
      adminTasks: 'appAdminTasks/adminTasks',
    }),
    adminJobs() {
      
      return this.adminTasks.map((task) => {
        let startTimeResult = task.start_time.toString();
        let endTimeResult = task.end_time.toString();

        let startTime = startTimeResult.substring(0,16);
        let endTime = endTimeResult.substring(0,16)

        return {
          id: task.id,
          title: task?.assignable?.reference ?? null,
          with: task?.assignedTo?.full_name ?? null,
          colorScheme: task.status,
          time: {
            start: startTime,
            end: endTime,
          },
          description: task.description,
          isCustom: false,
          isEditable: false,
          color: task.status == "In Progress" ? 'yellow' :
                 task.status == "Processing" ? 'blue' :
                 task.status == "Completed" ? 'green' :
                 task.status == "Closed" ? 'red' :
                 "red"
        };
      })
    },
    countList() {
      return this.adminTasks.map((task) => task.assignedTo.id).reduce((accumulator, value) => {
        return {
          ...accumulator,
          [value]: (accumulator[value] || 0) + 1,
        };
      }, {});
    }
  },
  methods: {
    ...mapActions({
      getAdmins: 'appAdmins/getAdmins',
      getAdminTasks: 'appAdminTasks/getAdminTasks',
    }),
    dateFormat(dateString) {
      let date = new Date(dateString);
      let formatDate = date.toISOString().slice(0, 10);
      return `${formatDate}`;
    },
    eventWasClicked(event) {
      console.log('eventWasClicked', {event})
    },
    eventWasResized(event) {
      console.log('eventWasResized', {event})
    },
    eventWasDragged(event) {
      console.log('eventWasDragged', {event})
    },
    editEvent(event) {
      console.log('editEvent', {event})
    },
    deleteEvent(event) {
      console.log('deleteEvent', {event})
    },
    intervalWasClicked(event) {
      console.log('intervalWasClicked', {event})
    },
    dayWasClicked(event) {
      console.log('dayWasClicked', {event})
    },
    datetimeWasClicked(event) {
      console.log('datetimeWasClicked', {event})
    },
    updatedMode(event) {
      console.log('updatedMode', {event})
    },
    updatedPeriod(event) {
      console.log('updatedPeriod', {event})
    },
    async getAdminList() {
      this.loader(true);
      const params = {
        status: 1,
        with_relation: ['user'],
      }
      await this.getAdmins(params).then(async (response) => {
        this.loader(false);
        if (response.message && response.status !== 200) {
          this.showToastMessage(response);
        }
      });
    },

    async getAdminTaskList() {
      this.loader(true);
      const params = {
        with_relation: ['creator', 'assignedTo', 'assignable']
      }
      await this.getAdminTasks(params).then(async (response) => {
        this.loader(false);
        if (response.message && response.status !== 200) {
          this.showToastMessage(response);
        }
      });
    },


  },
  async mounted() {
    this.loader(true);
    await this.getAdminList();
    await this.getAdminTaskList();
    this.loader(false);

  }
}
</script>

<style scoped>

</style>